/// @ts-nocheck
import { getConfig } from "src/config/web-config.js";

const config = getConfig();

export function initHeureka() {
    if (config.heureka) {
        (function(t, r, a, c, k, i, n, g) {
            t['ROIDataObject'] = k;
            t[k]=t[k]||function(){(t[k].q=t[k].q||[]).push(arguments)},t[k].c=i;n=r.createElement(a),
            g=r.getElementsByTagName(a)[0];n.async=1;n.src=c;g.parentNode.insertBefore(n,g)
        })(window, document, 'script', `//www.heureka.${config.country}/ocm/sdk.js?version=2&page=thank_you`, 'heureka', config.country);
    }
}

export function trackProductHeureka() {
    if (config.heureka) {
        (function(t, r, a, c, k, i, n, g) {t['ROIDataObject'] = k;
            t[k]=t[k]||function(){(t[k].q=t[k].q||[]).push(arguments)},t[k].c=i;n=r.createElement(a),
            g=r.getElementsByTagName(a)[0];n.async=1;n.src=c;g.parentNode.insertBefore(n,g)
        })(window, document, 'script', `//www.heureka.${config.country}/ocm/sdk.js?version=2&page=product_detail`, 'heureka', config.country);
    }
}