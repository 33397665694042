import {
    activateGtagAnalytics,
    activateGtagRemarketing,
    deactivateGtagRemarketing,
    loadGtag,
    unloadGtag
} from "../analytics/tracking-3rd/gtag";
import {TrackingScriptType} from "./TrackingScriptType";
import {activateFbPixel, deactivateFbPixel, initFbPixel} from "../analytics/tracking-3rd/fb-pixel";
import {loadSeznamRetargeting} from "../analytics/tracking-3rd/seznam-retargeting";
import initSmartsupp from "../analytics/tracking-3rd/smartsupp";
import {
    SCRIPT_ECOMAIL,
    SCRIPT_FB_PIXEL,
    SCRIPT_GOOGLE_ANALYTICS_BASE,
    SCRIPT_GOOGLE_REMARKETING, SCRIPT_HEUREKA, SCRIPT_SEZNAM_RETARGETING, SCRIPT_SMARTSUPP
} from "./script-list";
import {loadEcomail} from "../analytics/tracking-3rd/ecomail";
import { initHeureka } from "src/analytics/tracking-3rd/heureka.js";

export const scripts: TrackingScriptType[] = [
    {
        slug: SCRIPT_GOOGLE_ANALYTICS_BASE,
        activate: activateGtagAnalytics,
        deactivate: unloadGtag,
        always: loadGtag,
        loaded: false,
        activated: false,
    },
    {
        slug: SCRIPT_GOOGLE_REMARKETING,
        activate: activateGtagRemarketing,
        deactivate: deactivateGtagRemarketing,
        loaded: false,
        activated: false,
    },
    {
        slug: SCRIPT_FB_PIXEL,
        activate: activateFbPixel,
        deactivate: deactivateFbPixel,
        always: initFbPixel,
        loaded: false,
        activated: false,
    },
    {
        slug: SCRIPT_SEZNAM_RETARGETING,
        activate: loadSeznamRetargeting, // When seznam figures out how not to save all the cookies right away, move to always
        deactivate: null,
        always: null,
        loaded: false,
        activated: false,
    },
    {
        slug: SCRIPT_SMARTSUPP,
        activate: initSmartsupp,
        deactivate: null,
        loaded: false,
        activated: false,
    },
    {
        slug: SCRIPT_ECOMAIL,
        activate: loadEcomail,
        deactivate: null,
        loaded: false,
        activated: false,
    },
    {
        slug: SCRIPT_HEUREKA,
        activate: initHeureka,
        deactivate: null,
        loaded: false,
        activated: false,
    },
];
